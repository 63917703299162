<template>
  <div class="intro-y flex items-center mt-8">
    <h2 class="text-lg font-medium mr-auto">
      Update Profile
    </h2>
  </div>
  <div class="grid grid-cols-12 gap-6">
    <!-- BEGIN: Profile Menu -->
    <div class="col-span-12 lg:col-span-4 xxl:col-span-3 flex lg:block flex-col-reverse">
      <div class="intro-y box mt-5">
        <div class="relative flex items-center p-5">
          <div class="w-12 h-12 image-fit">
            <img
              alt="Quant Lines"
              class="rounded-full"
              :src="currentUser.profile_photo"
            >
          </div>
          <div class="ml-4 mr-auto">
            <div class="font-medium text-base">
              {{ currentUser.first_name + ' ' + currentUser.last_name }}
            </div>
          </div>
        </div>
        <div class="p-5 border-t border-gray-200 dark:border-dark-5">
          <router-link
            :to="{name: 'dashboard.profile.settings.personal-information'}"
            class="flex items-center font-medium"
            :class="{'text-theme-1': $route.name === 'dashboard.profile.settings.personal-information'}"
          >
            <i class="ri-focus-2-fill mr-2" /> Personal Information
          </router-link>
          <router-link
            :to="{name: 'dashboard.profile.settings.change-password'}"
            class="flex items-center mt-2 font-medium"
            :class="{'text-theme-1': $route.name === 'dashboard.profile.settings.change-password'}"
          >
            <i class="ri-lock-password-fill mr-2" /> Change Password
          </router-link>
        </div>
      </div>
    </div>
    <!-- END: Profile Menu -->
    <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
      <router-view />
    </div>
  </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    export default {
        name: "SettingsContainer",
        computed: {
            ...mapGetters('profile', ['currentUser'])
        }
    }
</script>

<style scoped>

</style>